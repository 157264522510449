

let imageList = [
  'images/gallery1.jpeg',
  'images/gallery2.jpg',
  'images/gallery3.jpg',
  'images/gallery4.jpeg',
  'images/gallery5.png',
  'images/gallery6.jpg',
  'images/gallery7.png',
  'images/gallery8.jpg',
  'images/gallery9.jpg',
  'images/gallery10.jpeg',
  'images/gallery11.jpg',
  'images/gallery12.jpeg',
  'images/gallery13.jpeg',
  'images/gallery14.jpg',
  'images/gallery15.jpg',
  'images/gallery16.jpeg',
  'images/gallery17.jpeg',

]

const createFancyImage = () => {
  const galleryWrap = document.querySelector('.gallery__wrap');

  imageList.forEach(img => {
    galleryWrap?.insertAdjacentHTML('afterbegin', `
    <a href="${img}" data-fancybox="gallery">
    <img src="${img}" />
  </a>`)
  })

  Fancybox.bind('[data-fancybox="gallery"]', {
    // Your custom options for a specific gallery
  });
}

createFancyImage()

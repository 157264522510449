const sendOrder = () => {



  form?.addEventListener('submit', async e => {
    e.preventDefault();
    const TOKEN = "5632472142:AAFAUEACJS8ehAaJgtpxSJhjTr1ks8ZvfxQ";
    const chatId = "-699606518";
    const APIURI = `https://api.telegram.org/bot${TOKEN}/sendMessage`;
    const sucssesBlock = document.querySelector('.sucsses')
    const sucssesText = document.querySelector('.sucsses>span');
    const formSubmit = document.querySelector('.form__submit')

    // const ObjData = {
    //   'Имя клиента': form.name.value,
    //   'Номер телефона': form.phone.value,
    //   'Услуга': form.querySelector('[data-service]').textContent.trim(),
    //   'Дата': form.date.value,
    // }
    const text = `Новая заявка\nИмя клиента: ${form.name.value},\nНомер телефона : ${form.phone.value},\nУслуга: ${form.querySelector('[data-service]').textContent.trim()},\nДата: ${form.date.value}`

    try {
      formSubmit.textContent = 'Отправка';
      formSubmit.style.cssText = 'pointer-events: none;'
      const responce = await fetch(APIURI, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chat_id: chatId,
          text,
        })
      })
      if (responce.ok) {
        sucssesText.textContent = `Спасибо ${form.name.value}, скоро я Вам перезвоню`
        sucssesBlock.classList.add('sucsses_active');

      }
      else throw new Error()

    } catch (error) {
      sucssesText.textContent = `${form.name.value}, произошла ошибка, обновите страницу`
      sucssesBlock.classList.add('sucsses_active');

    }
    finally {
      setTimeout(() => {
        formSubmit.textContent = 'Отправить';
        sucssesBlock.classList.remove('sucsses_active');
        formSubmit.style.cssText = '';
        formSubmit.disabled = true
      }, 2500)

    }

  })

}

sendOrder()
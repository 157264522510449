const popup = document.querySelector('.popup');

const openModal = (e) => {

  e.preventDefault()
  window.addEventListener('keyup', closeModal)
  popup.style.cssText = `visibility: visible;opacity:1;`;
  scrollHandler.disabledScroll()
}


const closeModal = (e) => {

  if (e.target === popup || e.target.closest('.close') || e.key == "Escape") {
    window.removeEventListener('keyup', closeModal)
    popup.style.cssText = `opacity:0;`;
    setTimeout(() => {
      popup.style.visibility = 'hidden'

      scrollHandler.enabledScroll()
    }, 300)
  }

}

const openPopup = () => {

  const headerBtn = document.querySelectorAll('.about');
  headerBtn?.forEach(element => {
    element.addEventListener('click', openModal);
  });
  popup?.addEventListener('click', closeModal)

}
openPopup();



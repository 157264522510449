
// проверка формы 
const pattern = {
  name: /^[а-яА-Я]{3,15}$/i,
  phone: /^[0-9]{11}$/i,
}






const debounceTimer = (fn, msec) => {
  let lastCall = 0;
  let lastCallTimer;

  return (...arg) => {
    const previousCall = lastCall;
    lastCall = Date.now();

    if (previousCall && ((lastCall - previousCall <= msec))) clearTimeout(lastCallTimer)

    lastCallTimer = setTimeout(() => {
      fn(...arg)
    }, msec)
  }
}

// доп фукция для валидации формы и отображения ошибок
const checkformat = (redexp, name, w) => {

  if (!redexp?.test(w)) {
    name.closest('.form__input').querySelector('.form__error').style.display = 'block';
  }
  else {
    name.closest('.form__input').querySelector('.form__error').style.display = 'none';
  }
}

//fn для проверки формы при вводе в input
const validForm = (name, redexp) => {
  // console.log('name: ', name.value);
  if (name.name === 'phone') {
    let phonesValue = name.value.replace(/\D/g, '');
    checkformat(redexp, name, phonesValue)
  }
  else checkformat(redexp, name, name.value);

}


const checkToDisaAbleBtn = () => {
  if (pattern.phone.test(form.phone.value.replace(/\D/g, '')) && pattern.name.test(form.name.value) && formChecked.dataset.service.length > 0 && datepicker.value !== '') {
    formSubmitBtn.disabled = false;
  }
  else formSubmitBtn.disabled = true;

}

form?.addEventListener('input', debounceTimer((e) => {
  validForm(e.target, pattern[e.target.name])
  checkToDisaAbleBtn()
}, 1000));


document.querySelector('#datepicker')?.addEventListener('click', debounceTimer((e) => {
  if (!e.target.classList.contains('datepicker')) return
  checkToDisaAbleBtn()
}, 1000));

form?.querySelector('.form__option-group').addEventListener('click', e => {
  if (!e.target.classList.contains('form__option')) return
  checkToDisaAbleBtn()
})



const headerBurger = document.querySelector('.header__burger');
const nav = document.querySelector('.nav');
const form = document.querySelector('.form');
const selectElem = document.querySelector('.form__select');
const optionGroup = document.querySelector('.form__option-group');

const headerBurgerElem = document.querySelector('.header__burger');
const headerTop = document.querySelector('.header__top');
const headerNavElem = document.querySelector('.header__nav');
const mainObserver = document.querySelectorAll('.main-observer');
const maskPhone = document.querySelector('[name="phone"]');
const formSubmitBtn = document.querySelector('.form__submit');
const formChecked = document.querySelector('.form__checked');
const datepicker = document.querySelector('#datepicker');






const scrollHandler = {
  disabledScroll() {
    const rightPaddingBody = window.innerWidth - document.body.offsetWidth;

    document.body.style.paddingRight = rightPaddingBody + 'px';
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden'
  },
  enabledScroll() {
    document.body.style.paddingRight = ''
    document.body.style.overflow = '';
    document.documentElement.style.overflow = 'auto'

  }
}






headerBurger.addEventListener('click', e => {
  e.preventDefault();
  const target = e.target.closest('.header__burger');
  if (target) {

    target.classList.toggle('burger_active');
    nav.classList.toggle('nav_active');

    if (target.classList.contains('burger_active') && window.innerWidth < 576) scrollHandler.disabledScroll();

    else scrollHandler.enabledScroll();

  };
})








selectElem?.addEventListener('click', e => {
  optionGroup.classList.toggle('form__option-group-hide')
})


optionGroup?.addEventListener('click', e => {
  optionGroup.classList.add('form__option-group-hide');
  formChecked.dataset.service = e.target.dataset.service;
  formChecked.textContent = e.target.textContent
})





const options = {
  threshold: 0.2,
}
const callbackObserve = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      headerBurgerElem.classList.add('header__burger-fixed');
      headerTop.classList.add('header__top-fixed')
      headerNavElem.classList.add('nav-fixed');

    }
    else {
      headerBurgerElem.classList.remove('header__burger-fixed')
      headerNavElem.classList.remove('nav-fixed');
      headerTop.classList.remove('header__top-fixed');
    }
  })
}
const observers = new IntersectionObserver(callbackObserve, options);


if (window.innerWidth < 560) {
  mainObserver.forEach((elem) => observers.observe(elem))

}
















try {
  const im = new Inputmask("+7(999) 999-99-99");
  im.mask(maskPhone);

  const hidePlaceholderTel = () => maskPhone.placeholder = '';

  maskPhone.addEventListener('mouseenter', hidePlaceholderTel)
  maskPhone.removeEventListener('mouseleave', hidePlaceholderTel)

} catch (error) {

}





try {
  const picker = new easepick.create({

    element: document.getElementById('datepicker'),
    css: [
      'css/index.css',
    ],
    zIndex: 10,
    format: "DD.MM.YYYY",
    lang: "ru-RU",
    minDate: "today",

  });

} catch (error) {
}





document.querySelector('.easepick-wrapper')?.addEventListener('click', e => {
  e.target.closest('.form__input-wrap').querySelector('label').style.cssText = `transform: translateY(-0.625rem); font-size: 0.8125rem; transition-property: transform, font-size; transition-duration: 400ms; `;
})






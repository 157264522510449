
const payElem = document.querySelector('.payment__form');

const rndID = () => {
  return Math.random().toString(16).slice(4)
}




const createForm = () => {
  payElem.insertAdjacentHTML('beforeend', `
  <form method="POST" action="https://yoomoney.ru/quickpay/confirm">
  <input type="hidden" name="receiver" value="410011349704744" />
  <input type="hidden" name="label" value="${rndID()}" />
  <input type="hidden" name="quickpay-form" value="button" />
  <input type="hidden" name="sum" value="500" data-type="number" />
  <div class="payment-wrap">
    <label >
      <input class="money" type="radio" name="paymentType" value="PC" />
    </label>
    <label >
      <input class="card" type="radio" name="paymentType"  value="AC" />
      </label>
  </div>


  <div class="payment-control">
    <label class="payment-sum-label"><input type="number" class="payment-sum"  value="500">
    <span class=symbol>руб.</span></label>
    <input type="submit" value="Оплатить" />
  </div>
  </form>
  `)

  return {
    inputSum: document.querySelector('.payment-sum'),
    hiddenSum: document.querySelector('[name="sum"]'),
    formСheckedValue: document.querySelector('.form__checked')
  }
}




if (location.pathname.includes('payment')) {
  let paySum = createForm()

  paySum.inputSum.addEventListener('input', e => {
    let curentValue = e.target.value;
    paySum.hiddenSum.value = curentValue.trim();

  })
}

const swiperCertificate = new Swiper('.swiper-certificate', {

  loop: false,
  mousewheel: {
    invert: false,
  },
  threshold: 5,
  simulateTouch: true,


  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  centeredSlides: true,
  breakpoints: {

    320: {
      slidesPerView: 1.7,
      spaceBetween: 10
    },


    640: {
      slidesPerView: 1,
      spaceBetween: 10
    }
  },

});

const swiperFeedback = new Swiper('.swiper-feedback', {
  spaceBetween: 20,
  loop: true,

  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
  },

});


const swipermaterial = new Swiper('.swiper-material', {
  spaceBetween: 20,
  loop: false,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
  slidesPerView: 4,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.5,
      spaceBetween: 20
    },
    374: {
      slidesPerView: 1.8,
      spaceBetween: 20
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3.2,
      spaceBetween: 30
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 4,
      spaceBetween: 40
    }
  },


});




try {

  const certificateWrap = document.querySelector('.certificate__wrap');


  certificateWrap.addEventListener('click', e => {
    if (e.target.closest('.swiper-slide')) {
      openCertificateImg(e)
    }

  })

  const openCertificateImg = (e) => {
    scrollHandler.disabledScroll();

    window.addEventListener('keydown', closeCertificateImg)
    let currentImg = e.target.closest('.swiper-slide').querySelector('img');
    console.log('currentImg: ', currentImg.srcset);
    let divPopup = document.createElement('div');
    divPopup.className = 'popup-certificate';
    divPopup.innerHTML = ` 
  <img src="${currentImg.src}" srcset="${currentImg.srcset}" alt="${currentImg.getAttribute('alt')}">`;

    document.body.append(divPopup);
    setTimeout(() => { divPopup.style.opacity = '1' }, 100)
  }

  const closeCertificateImg = (e) => {

    if (e.target.classList.contains('popup-certificate') || e.key == 'Escape') {
      window.removeEventListener('keydown', closeCertificateImg);


      const popupElem = document.querySelector('.popup-certificate')
      popupElem.style.opacity = '0';

      setTimeout(() => {
        scrollHandler.enabledScroll();
        popupElem.remove()
      }, 100)
    }


  }


  document.body.addEventListener('click', closeCertificateImg)
} catch (error) {

}
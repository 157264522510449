//preloader
const allImg = document.querySelectorAll('img,video');
let loadedImg = 0;


const imageLoaded = () => {
  loadedImg++;
  percent.textContent = Math.trunc((100 / allImg.length) * loadedImg) + '%';

  if (loadedImg >= allImg.length) {
    setTimeout(() => {
      const spinnerWrap = document.querySelector('.spinner-wrap');
      if (!spinnerWrap.classList.contains('done')) {
        spinnerWrap.classList.add('done');
        document.querySelector('html').style.overflowY = 'inherit'
        setTimeout(() => { spinnerWrap.remove() }, 300)
      }
    }, 1000)
  }
}



for (let i = 0; i < allImg.length; i++) {
  let imageClone = new Image()
  imageClone.onload = imageLoaded;
  imageClone.onerror = imageLoaded;
  imageClone.src = allImg[i].src;
}
